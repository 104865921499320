<template>
  <v-card>
      <v-card-title>
        <span class="headline">Ajouter un sous module</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row> 
            <v-col>
              <v-autocomplete
                ref="userSelect"
                :items="users"
                :item-text="item => `${item.firstname} ${item.lastname}`"
                :label="'Développeurs*'"
                multiple
                v-model="usersSelected"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.firstname + ' ' + data.item.lastname }}
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.firstname + ' ' + data.item.lastname }}
                </template>
              </v-autocomplete> 
            </v-col>
            <v-col>
              <v-text-field 
                v-model="version" 
                label="Version" 
                ></v-text-field>
            </v-col>
          </v-row>
          <v-row> 
            <v-col>
              <v-text-field 
                v-model="sousModule" 
                label="Sous-module" 
                :disabled="sousModuleDisabled"
                ></v-text-field>
            </v-col>
            <v-col>
              <v-autocomplete
                :items="allSousModule"
                :item-text="item => `${item.sous_module}`"
                :label="'Dépendances'"
                v-model="sousModuleExist"
                deletable-items
                multiple
              > 
                <template slot="item" slot-scope="data">
                  {{ data.item }}
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row> 
            <v-col cols="6">
              <v-text-field 
                v-model="technologie" 
                label="Technologie" 
                ></v-text-field>
            </v-col>
          <!-- lien sous module git -->
            <v-col cols="6">
              <v-text-field 
                v-model="sousModuleGit"
                label="Lien vers le référentiel Git" 
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- description -->
          <v-row>
            <v-col>
              <v-textarea
                v-model="sousModuleDescription"
                label="Description"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          color="blue darken-1" 
          text 
          @click="closeModal"
          >Annuler</v-btn>
        <v-btn 
          color="warning" 
          text @click="saveSousModule" 
          :disabled="!isValidate"
          >Sauvegarder</v-btn>
      </v-card-actions>
  </v-card> 
</template>
<script>

import UserService from '@/Services/UserService';
import * as Vuex from "vuex";
import ModuleService from "@/Services/SupportVision/ModuleService";
import Images from '@/Utils/Images';

export default {
  name: "createModule",
  components: {
    ClientPickerSelect: () => import("@/Components/Commons/Inputs/Client/ClientPickerSelect")
  },
  data() {
    return {
      version: '',
      users: [],
      usersSelected: [],
      module: '',
      sousModule: [],
      sousModuleExist: '',
      technologie: '',
      sousModuleDescription: '',
      sousModuleGit: '',
      file: null,
      fileRules: [
        v => !!v || 'File is required'
      ],
      logo: null,
      fileName: null,
      newLogoPreview: null,
      data: [],
      dataSousModule: [],
      idSousModule: null,
      sousModuleDisabled: false,
      allSousModule: [],
    }
  }, 
  computed: {
    ...Vuex.mapState(['moduleId', 'moduleItem']),
    isValidate() {
      return !!this.version && !!this.usersSelected.length && (!!this.sousModule || !!this.sousModuleExist.length ) && !!this.technologie;
    },
    // getSousModule() {
    //   return this.dataSousModule.map((item) => item.sous_module.map((sousModule) => sousModule.sous_module + ' - V' + sousModule.version ))[0];
    // },
  },
  // watch les changements de la variable moduleId
  watch: {
    moduleId: function (val) {
      this.loadData();
    }, 
  },
  methods: {
    closeModal() {
      this.resetForm();
      this.$emit('close-edit');
    },
    openModalSousModule() {
      this.dialogSousModule = true
    }, 
    resetForm() {
      this.version = '';
      this.usersSelected = [];
      this.sousModule = '';
      this.sousModuleExist = '';
      this.technologie = '';
      this.sousModuleDescription = '';
      this.sousModuleGit = '';
      this.logo = null;
      this.newLogoPreview = null;
    },
    async handleLogoChange(file) {
      if (file) {
        let b64 = await Images.toBase64(file);
        this.newLogoPreview = b64;
        this.logo = file
      } else {
        this.newLogoPreview = null;
        this.logo = null;
      }
    },
    async saveSousModule() {
      
      const formData = new FormData();
      for (const user of this.usersSelected) {
        formData.append('users[]', user);
      }
      // formData.append('module', this.module);
      // si sousModule est un tableau
      if (Array.isArray(this.sousModule)) {
        for (const sousModule of this.sousModule) {
          formData.append('sousModule[]', sousModule);
        }
      } else {
        formData.append('sousModule', this.sousModule);
      }
      formData.append('sousModuleExist', Array.isArray(this.sousModuleExist) && this.sousModuleExist.length > 0 ? this.sousModuleExist[0].split(' - V')[0] : '');

      formData.append('version', this.version);
      formData.append('technologie', this.technologie);
      formData.append('sousModuleDescription', this.sousModuleDescription);
      formData.append('sousModuleGit', this.sousModuleGit);
      await ModuleService.updateModule(this.moduleId, formData);
      this.$emit('refresh');
      this.resetForm();
      this.closeModal();
    },
    async loadData() {
      await UserService.getUsers().then((res) => {
          this.users = res;
        }); 
      await ModuleService.getModules(this.moduleId).then((res) => {
        this.dataSousModule = res.data
      });
      await ModuleService.getSousModules().then((res) => {
      const allSousModules = res.map((item) => item.sous_module + ' - V' + item.version);
      this.allSousModule = allSousModules;
      });
      // filtre les sous modules déjà utilisés
      const usedSousModule = this.moduleItem.sous_module.map((item) => item.sous_module);
      this.allSousModule = this.allSousModule.filter((item) => !usedSousModule.includes(item.split(' - V')[0]));
    }
  },
  async mounted() { 
    await this.loadData();
  }
}
</script>

